import { MyIntegerEnum } from './index'

const Pesagem = {
  Tara: MyIntegerEnum.create(1, 'Tara'),
  Bruto: MyIntegerEnum.create(2, 'Bruto'),
  Operacao: MyIntegerEnum.create(3, 'Operacao'),
  PBO: MyIntegerEnum.create(4, 'PBO'),
  PLO: MyIntegerEnum.create(5, 'PLO'),
}

const getById = (id) => {
  const entrie = Object.entries(Pesagem).filter((i) => i[1].id == id)
  if (entrie.length > 0) {
    return entrie[0][1].name
  }
  return ''
}

export { Pesagem, getById }
