<template>
  <v-dialog persistent v-model="dialog" max-width="1080px">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="d-flex flex-column align-start">
        <div class="w-100 d-flex">
          <span class="text-h5">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')"> Fechar </v-btn>
        </div>
        <div class="d-flex w-100">
          <span class="text-caption"
            >Agendamento: {{ agendamento ? agendamento.public_id : '' }}</span
          >
        </div>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <FormPesagem
                v-model="pesagem"
                :tipos_pesagens="tipos_pesagens"
                :gates_services="gates_services"
                :justificativas="justificativas"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          v-if="show_actions.return"
          @click="save(actions.return)"
          >Retorno</v-btn
        >
        <v-btn
          color="secondary"
          v-if="show_actions.confirm"
          @click="save(actions.confirm)"
          >Confirmar</v-btn
        >
        <v-btn color="primary" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Pesagem, getById } from '@/utils/pesagem'
import { BRString2datetimeIso } from '@/mixins/convertion'
import FormPesagem from '@/components/patio/NovoAgendamento/Etapas/FormPesagem'
import PatioApi from '@/services/patio'
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    agendamento: {
      type: Object,
      required: true,
    },
  },
  mixins: [BRString2datetimeIso],
  components: {
    FormPesagem,
  },
  data() {
    return {
      loading: false,
      pesagem: {
        public_id: '',
        peso: 0,
        data: '',
        justificativa: null,
        tipo_pesagem: Pesagem.Tara.id,
        observacao: '',
        gate_service: null,
      },
      tipos_pesagens: [],
      justificativas: [],
      gates_services: [],
      actions: {
        default: {},
        confirm: { confirmar: true },
        return: { retornar: true },
      },
      show_actions: {
        confirm: false,
        return: false,
      },
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    title() {
      return `Pesagem ${this.tipo_pesagem} - ${this.agendamento?.veiculo?.placa_cavalo}`
    },
    tipo_pesagem() {
      return getById(this.pesagem.tipo_pesagem)
    },
  },
  methods: {
    ...mapActions('patio', []),
    ...mapActions('supervisorio', ['pesar', 'getGates']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async getJustificativas() {
      try {
        this.justificativas = await PatioApi.getJustificativas(
          this.empresaAtual
        )
      } catch (error) {
        console.error(error)
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async getGate() {
      try {
        const { results } = await this.getGates({
          proprietario: this.agendamento.terminal.contratado,
        })
        this.gates_services = results
      } catch (error) {
        console.error(error)
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    validaPesagem() {
      if (this.pesagem.peso <= 0) {
        console.error('Peso de ser maior que 0!')
        this.errorMessage('Peso de ser maior que 0!')
        return false
      }
      let data_valida = true
      if (this.pesagem.data != '') {
        let data
        try {
          data = new Date(this.BRString2datetimeIso(this.pesagem.data))
          if (isNaN(data)) {
            data_valida = false
          }
        } catch (error) {
          console.error(error)
          data_valida = false
        }
      }
      if (!data_valida) {
        this.errorMessage(`Data (${this.pesagem.data}) precisa ser valida!`)
        return false
      }
      return true
    },
    async save(action = this.actions.default) {
      if (!this.validaPesagem()) return
      try {
        const data_pesagem = this.BRString2datetimeIso(this.pesagem.data)
        await this.pesar({
          ...this.pesagem,
          agendamento: this.agendamento.public_id,
          proprietario: this.agendamento.terminal.contratado,
          manual: true,
          data: data_pesagem ? new Date(data_pesagem).toISOString() : null,
          ...action,
        })
        this.successMessage('Pesagem registrada!')
        this.$emit('close')
      } catch (error) {
        this.show_actions.confirm = true
        this.show_actions.return = true
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
  },
  async created() {
    this.tipos_pesagens = [
      { id: Pesagem.Tara.id, descricao: Pesagem.Tara.name },
      { id: Pesagem.Bruto.id, descricao: Pesagem.Bruto.name },
      { id: Pesagem.Operacao.id, descricao: Pesagem.Operacao.name },
    ]
    if (this.agendamento.tipo_operacao?.descricao.toLowerCase() == 'baldeio') {
      const pesou_entrada = this.agendamento.pesagens.findLast(
        (item) => item.tipo_pesagem == Pesagem.Tara.id
      )
      if (pesou_entrada) {
        this.pesagem.tipo_pesagem = Pesagem.Operacao.id
      }
    }
    if (this.agendamento.tipo_operacao?.descricao.toLowerCase() == 'carga') {
      const pesou_entrada = this.agendamento.pesagens.findLast(
        (item) => item.tipo_pesagem == Pesagem.Tara.id
      )
      if (
        !pesou_entrada
        // || pesou_entrada.retornar
      ) {
        this.pesagem.tipo_pesagem = Pesagem.Tara.id
      } else {
        this.pesagem.tipo_pesagem = Pesagem.Bruto.id
      }
    }
    if (this.agendamento.tipo_operacao?.descricao.toLowerCase() == 'descarga') {
      const pesou_entrada = this.agendamento.pesagens.findLast(
        (item) => item.tipo_pesagem == Pesagem.Bruto.id
      )
      if (
        pesou_entrada
        // && !pesou_entrada.retornar
      ) {
        this.pesagem.tipo_pesagem = Pesagem.Tara.id
      } else {
        this.pesagem.tipo_pesagem = Pesagem.Bruto.id
      }
    }

    this.loading = true
    this.getGate()
    this.getJustificativas()
    // this.justificativas = await PatioApi.getJustificativas(this.agendamento.gate)
    this.loading = false
  },
}
</script>
